// import React, { useState, useEffect } from 'react';
// import { Grid, TextField } from '@mui/material';
// import Button from 'react-bootstrap/Button';
// import APIService from '../../../../APIService';
// import swal from 'sweetalert';
// import { Table } from 'react-bootstrap';

// export default function CGRF() {
//   const [cgrf, setCgrf] = useState([]);
//   const [caption, setCaption] = useState("");
//   const [description, setDescription] = useState("");
//   const [fileUpload, setFileUpload] = useState("");
//   const [cgrfId, setCgrfId] = useState("");
//   const [isEditing, setIsEditing] = useState(false);

//   useEffect(() => {
//     fetchCgrf();
//   }, []);

//   const fetchCgrf = () => {
//     APIService.get('cgrf').then((response) => {
//       setCgrf(response.data);
//     });
//   };

//   const handleFileChange = (e) => {
//     if (e.target.files) {
//       setFileUpload(e.target.files[0]);
//     }
//   };

//   const handleEdit = (itemId, caption, description) => {
//     setIsEditing(true);
//     setCgrfId(itemId);
//     setCaption(caption);
//     setDescription(description);
//   };

//   const handleDelete = (itemId) => {
//     swal({
//       title: "Are you sure?",
//       text: "You want to delete this item?",
//       icon: "warning",
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         APIService.delete(`cgrf/${itemId}`).then((response) => {
//           swal("Success", response.message, "success", {
//             buttons: false,
//             timer: 2000,
//           }).then(() => {
//             fetchCgrf();
//           });
//         });
//       }
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append("caption", caption);
//     formData.append("description", description);
//     if (fileUpload) {
//       formData.append("file", fileUpload);
//     }

//     try {
//       let response;
//       if (isEditing) {
//         response = await APIService.put(`cgrf/${cgrfId}`, formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });
//       } else {
//         response = await APIService.post('cgrf', formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });
//       }

//       swal("Success", response.data.message, "success", {
//         buttons: false,
//         timer: 2000,
//       }).then(() => {
//         resetForm();
//         fetchCgrf();
//       });
//     } catch (error) {
//       swal("Error", "An error occurred while submitting the form", "error");
//     }
//   };

//   const resetForm = () => {
//     setCaption("");
//     setDescription("");
//     setFileUpload("");
//     setCgrfId("");
//     setIsEditing(false);
//   };

//   return (
//     <div>
//       <h2 className="organisation-head">CGRF</h2>
//       <form noValidate onSubmit={handleSubmit}>
//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Caption</p>
//             <TextField
//               name="caption"
//               label="Caption"
//               variant="outlined"
//               fullWidth
//               value={caption}
//               onChange={e => setCaption(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Upload Image</p>
//             <TextField
//               name="upload-photo"
//               type="file"
//               onChange={handleFileChange}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={12} md={8}>
//             <p id="labels">Description</p>
//             <TextField
//               fullWidth
//               id="outlined-multiline-static"
//               label="Description"
//               multiline
//               rows={3}
//               value={description}
//               onChange={e => setDescription(e.target.value)}
//             />
//           </Grid>
//         </Grid>
//         <Button style={{ marginTop: 20 }} variant="primary" type="submit">
//           {isEditing ? "Update" : "Submit"}
//         </Button>{' '}
//         {isEditing && (
//           <Button style={{ marginTop: 20 }} variant="secondary" onClick={resetForm}>
//             Cancel
//           </Button>
//         )}
//       </form>
//       <Table striped bordered hover variant="light">
//         <thead>
//           <tr>
//             <th>Sl No</th>
//             <th>Caption</th>
//             <th>Description</th>
//             <th>Edit</th>
//             <th>Delete</th>
//           </tr>
//         </thead>
//         <tbody>
//           {cgrf.map((cg, index) => (
//             <tr key={cg.id}>
//               <td>{index + 1}</td>
//               <td>{cg.caption}</td>
//               <td>{cg.description}</td>
//               <td>
//                 <button className='tablebutton'
//                   onClick={() => handleEdit(cg.id, cg.caption, cg.description)}
//                 >
//                   Edit
//                 </button>
//               </td>
//               <td>
//                 <button className='tablebutton'
//                   onClick={() => handleDelete(cg.id)}>
//                   Delete
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { Grid, TextField } from '@mui/material';
// import Button from 'react-bootstrap/Button';
// import APIService from '../../../../APIService';
// import swal from 'sweetalert';
// import { Table } from 'react-bootstrap';

// export default function CGRF() {
//   const [cgrf, setCgrf] = useState([]);
//   const [caption, setCaption] = useState("");
//   const [jurisdiction, setJurisdiction] = useState("");
//   const [description, setDescription] = useState("");
//   const [name, setName] = useState("");
//   const [designation, setDesignation] = useState("");
//   const [mobile, setMobile] = useState(""); // Changed from contactNumber to mobile
//   const [mailId, setMailId] = useState("");
//   const [fileUpload, setFileUpload] = useState(null);
//   const [sortOrder, setSortOrder] = useState("");
//   const [errors, setErrors] = useState({});
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const [cgrfId, setCgrfId] = useState("");
//   const [isEditing, setIsEditing] = useState(false);
//   const [currentImage, setCurrentImage] = useState(null);

//   useEffect(() => {
//     fetchCGRFData();
//   }, []);

//   const fetchCGRFData = () => {
//     APIService.get('cgrf').then((response) => {
//       setCgrf(response.data);
//     }).catch(error => {
//       console.error('Error fetching CGRF data:', error);
//       swal("Error", "Failed to load CGRF data", "error");
//     });
//   };

//   const handleFileChange = (e) => {
//     if (e.target.files) {
//       setFileUpload(e.target.files[0]);
//     }
//   }

//   const validateForm = () => {
//     const newErrors = {};
    
//     if (!caption.trim()) newErrors.caption = 'Caption is required';
//     if (!name.trim()) newErrors.name = 'Name is required';
//     if (!sortOrder) newErrors.sortOrder = 'Sort order is required';
    
//     // Validate mobile if provided
//     if (mobile.trim() && !/^\d{10}$/.test(mobile)) {
//       newErrors.mobile = 'Invalid phone number (10 digits required)';
//     }
    
//     // Validate email if provided
//     if (mailId.trim() && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mailId)) {
//       newErrors.mailId = 'Invalid email address';
//     }
    
//     // File upload required only if not editing or if editing with new file
//     if (!isEditing && !fileUpload) {
//       newErrors.fileUpload = 'Image is required';
//     }
    
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleEdit = (itemId, caption, jurisdiction, description, name, designation, mobile, email, order, image) => {
//     setIsEditing(true);
//     setCgrfId(itemId);
//     setCaption(caption || "");
//     setJurisdiction(jurisdiction || "");
//     setDescription(description || "");
//     setName(name || "");
//     setDesignation(designation || "");
//     setMobile(mobile || "");
//     setMailId(email || "");
//     setSortOrder(order || "");
//     setFileUpload(null);
//     setCurrentImage(image);
//     setErrors({});
//   };

//   const handleDelete = (itemId) => {
//     swal({
//       title: "Are you sure?",
//       text: "You want to delete this item?",
//       icon: "warning",
//       dangerMode: true,
//       buttons: ["Cancel", "Delete"]
//     }).then((willDelete) => {
//       if (willDelete) {
//         APIService.delete(`cgrf/${itemId}`).then((response) => {
//           swal("Success", response.message, "success", {
//             buttons: false,
//             timer: 2000,
//           }).then(() => {
//             fetchCGRFData();
//           });
//         }).catch(error => {
//           console.error('Delete error:', error);
//           swal("Error", "Failed to delete item", "error");
//         });
//       }
//     });
//   };

//   const changePosition = (direction, itemId) => {
//     APIService.post([], `change-position/${direction}/cgrf/${itemId}`)
//       .then((response) => {
//         if (response.success) {
//           fetchCGRFData();
//         }
//       })
//       .catch(error => {
//         console.error('Position change error:', error);
//         swal("Error", "Failed to change position", "error");
//       });
//   };
  
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);
    
//     if (!validateForm()) {
//       setIsSubmitting(false);
//       return;
//     }
  
//     const formData = new FormData();
//     formData.append("caption", caption);
//     formData.append("jurisdiction", jurisdiction);
//     formData.append("description", description);
//     formData.append("name", name);
//     formData.append("designation", designation);
//     formData.append("mobile", mobile);
//     formData.append("email", mailId);
//     formData.append("sort_order", sortOrder);
//     if (fileUpload) {
//       formData.append("image", fileUpload);
//     }
 
//     try {
//       let endpoint = "cgrf";
//       let method = "post";
      
//       if (isEditing) {
//         endpoint = `cgrf/${cgrfId}`;
//         method = "put";
//         formData.append("_method", "PUT");
//       }
  
//       const response = await APIService[method](formData, endpoint);
  
//       if (response.success) {
//         swal("Success", response.message, "success", {
//           buttons: false,
//           timer: 2000,
//         }).then(() => {
//           resetForm();
//           fetchCGRFData();
//         });
//       } else {
//         throw new Error(response.message || "Operation failed");
//       }
//     } catch (error) {
//       console.error('Submission error:', error);
//       let errorMessage = "An error occurred";
      
//       if (error.response) {
//         if (error.response.status === 422 && error.response.data.errors) {
//           const serverErrors = {};
//           Object.entries(error.response.data.errors).forEach(([field, messages]) => {
//             serverErrors[field] = Array.isArray(messages) ? messages.join(', ') : messages;
//           });
//           setErrors(serverErrors);
//           errorMessage = "Please fix the validation errors";
//         } else {
//           errorMessage = error.response.data.message || errorMessage;
//         }
//       } else if (error.message) {
//         errorMessage = error.message;
//       }
      
//       swal("Failed", errorMessage, "error");
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   const resetForm = () => {
//     setCaption("");
//     setJurisdiction("");
//     setDescription("");
//     setName("");
//     setDesignation("");
//     setMobile("");
//     setMailId("");
//     setFileUpload(null);
//     setSortOrder("");
//     setCgrfId("");
//     setCurrentImage(null);
//     setIsEditing(false);
//     setErrors({});
//   };

//   return (
//     <div>
//       <h2 className="organisation-head">CGRF</h2>
//       <form noValidate onSubmit={handleSubmit}>
//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Caption *</p>
//             <TextField
//               name="caption"
//               label="Caption"
//               variant="outlined"
//               fullWidth
//               value={caption}
//               onChange={(e) => setCaption(e.target.value)}
//               error={!!errors.caption}
//               helperText={errors.caption}
//               required
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Jurisdiction</p>
//             <TextField
//               name="jurisdiction"
//               label="Jurisdiction"
//               variant="outlined"
//               fullWidth
//               value={jurisdiction}
//               onChange={(e) => setJurisdiction(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Description</p>
//             <TextField
//               fullWidth
//               id="outlined-multiline-static"
//               label="Description"
//               multiline
//               rows={4}
//               value={description}
//               onChange={e => setDescription(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Name *</p>
//             <TextField
//               name="name"
//               label="Name"
//               variant="outlined"
//               fullWidth
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//               error={!!errors.name}
//               helperText={errors.name}
//               required
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Designation</p>
//             <TextField
//               name="designation"
//               label="Designation"
//               variant="outlined"
//               fullWidth
//               value={designation}
//               onChange={(e) => setDesignation(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Mobile Number</p>
//             <TextField
//               name="mobile"
//               label="Mobile Number"
//               variant="outlined"
//               fullWidth
//               value={mobile}
//               onChange={(e) => setMobile(e.target.value)}
//               error={!!errors.mobile}
//               helperText={errors.mobile}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Mail ID</p>
//             <TextField
//               name="email"
//               label="Mail ID"
//               variant="outlined"
//               fullWidth
//               value={mailId}
//               onChange={(e) => setMailId(e.target.value)}
//               error={!!errors.mailId}
//               helperText={errors.mailId}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Sort Order *</p>
//             <TextField
//               name="sort_order"
//               label="Sort Order"
//               variant="outlined"
//               fullWidth
//               type="number"
//               value={sortOrder}
//               onChange={(e) => setSortOrder(e.target.value)}
//               error={!!errors.sortOrder}
//               helperText={errors.sortOrder}
//               required
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Upload Image {!isEditing && '*'}</p>
//             <TextField
//               name="image"
//               type="file"
//               onChange={handleFileChange}
//               error={!!errors.fileUpload}
//               helperText={errors.fileUpload}
//               required={!isEditing}
//             />
//             {isEditing && currentImage && (
//               <div style={{ marginTop: '10px' }}>
//                 <p>Current Image:</p>
//                 <img 
//                   src={currentImage} 
//                   alt="Current" 
//                   style={{ maxWidth: '100px', maxHeight: '100px' }}
//                 />
//               </div>
//             )}
//           </Grid>
//         </Grid>
//         <Button 
//           style={{ marginTop: 20 }} 
//           variant="primary" 
//           type="submit"
//           disabled={isSubmitting}
//         >
//           {isSubmitting ? 'Submitting...' : 'Submit'}
//         </Button>
//         {isEditing && (
//           <Button 
//             style={{ marginTop: 20, marginLeft: 10 }} 
//             variant="secondary" 
//             onClick={resetForm}
//             disabled={isSubmitting}
//           >
//             Cancel
//           </Button>
//         )}
//       </form>
      
//       <Table striped bordered hover variant="light" style={{ marginTop: 30 }}>
//         <thead>
//           <tr>
//             <th style={{ width: '5%' }}>Sl No</th>
//             <th style={{ width: '10%' }}>Caption</th>
//             <th style={{ width: '10%' }}>Jurisdiction</th>
//             <th style={{ width: '20%' }}>Description</th>
//             <th style={{ width: '15%' }}>Name</th>
//             <th style={{ width: '10%' }}>Designation</th>
//             <th style={{ width: '10%', whiteSpace: 'nowrap' }}>Mobile</th>
//             <th style={{ width: '10%' }}>Mail ID</th>
//             <th style={{ width: '5%' }}>Image</th>
//             <th style={{ width: '5%' }}>Edit</th>
//             <th style={{ width: '5%' }}>Delete</th>
//             <th style={{ width: '5%' }}>Move Up</th>
//             <th style={{ width: '5%' }}>Move Down</th>
//           </tr>
//         </thead>
//         <tbody>
//           {cgrf.map((cg, index) => (
//             <tr key={cg.id}>
//               <td>{index + 1}</td>
//               <td>{cg.caption}</td>
//               <td>{cg.jurisdiction || "-"}</td>
//               <td style={{ width: '20%' }}>{cg.description || "-"}</td>
//               <td style={{ width: '15%' }}>{cg.name || "-"}</td>
//               <td>{cg.designation || "-"}</td>
//               <td style={{ whiteSpace: 'nowrap' }}>{cg.mobile || "-"}</td>
//               <td>{cg.email || "-"}</td>
//               <td>
//                 {cg.image && (
//                   <img 
//                     src={cg.image} 
//                     alt="CGRF" 
//                     style={{ maxWidth: '50px', maxHeight: '50px' }}
//                   />
//                 )}
//               </td>
//               <td>
//                 <button
//                   className="tablebutton"
//                   onClick={() =>
//                     handleEdit(
//                       cg.id,
//                       cg.caption,
//                       cg.jurisdiction,
//                       cg.description,
//                       cg.name,
//                       cg.designation,
//                       cg.mobile,
//                       cg.email,
//                       cg.sort_order,
//                       cg.image
//                     )
//                   }
//                 >
//                   Edit
//                 </button>
//               </td>
//               <td>
//                 <button className="tablebutton" onClick={() => handleDelete(cg.id)}>
//                   Delete
//                 </button>
//               </td>
//               <td>
//                 <button
//                   className='move-btn'
//                   onClick={() => changePosition("up", cg.id)}
//                 >
//                   <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
//                 </button>
//               </td>
//               <td>
//                 <button
//                   className='move-btn'
//                   onClick={() => changePosition("down", cg.id)}
//                 >
//                   <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// }



import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import Button from 'react-bootstrap/Button';
import APIService from '../../../../APIService';
import swal from 'sweetalert';
import { Table } from 'react-bootstrap';

export default function CGRF() {
  const [cgrf, setCgrf] = useState([]);
  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [jurisdiction, setJurisdiction] = useState("");
  const [contact_number, setContactNumber] = useState("");
  const [mail_id, setMailId] = useState("");
  const [fileUpload, setFileUpload] = useState(null);
  const [cgrfId, setCgrfId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchCgrf();
  }, []);

  const fetchCgrf = () => {
    APIService.get('cgrf').then((response) => {
      setCgrf(response.data || []);
    }).catch(error => {
      console.error("Fetch CGRF error:", error);
      swal("Error", "Failed to fetch CGRF data", "error");
    });
  };

  const handleFileChange = (e) => {
    setFileUpload(e.target.files[0]);
  };

  const handleEdit = (item) => {
    setIsEditing(true);
    setCgrfId(item.id);
    setCaption(item.caption || "");
    setDescription(item.description || "");
    setSortOrder(item.sort_order || "");
    setName(item.name || "");
    setDesignation(item.designation || "");
    setJurisdiction(item.jurisdiction || "");
    setContactNumber(item.contact_number || item.mobile || "");
    setMailId(item.mail_id || item.email || "");
    setFileUpload(null); // Reset file upload when editing
  };

  const handleDelete = (itemId) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`cgrf/${itemId}`).then((response) => {
          swal("Success", response.message || "Deleted successfully", "success", {
            buttons: false,
            timer: 2000,
          }).then(() => {
            fetchCgrf();
          });
        }).catch(error => {
          console.error("Delete error:", error);
          swal("Error", "Failed to delete item", "error");
        });
      }
    });
  };
  const changePosition = (direction, itemId) => {
    APIService.post([], `change-position/${direction}/cgrf/${itemId}`)
      .then((response) => {
        if (response.success) {
          fetchCgrf();
        }
      })
      .catch(error => {
        console.error('Position change error:', error);
        swal("Error", "Failed to change position", "error");
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate required fields
    if (!caption || !name || !designation || !jurisdiction || !contact_number || !mail_id) {
      swal("Error", "Please fill all required fields", "error");
      return;
    }
  
    // Validate image (only required for new entries)
    if (!isEditing && !fileUpload) {
      swal("Error", "Please upload an image", "error");
      return;
    }
  
    if (fileUpload && !fileUpload.name.match(/\.(jpg|jpeg|png)$/i)) {
      swal("Error", "Please upload a valid JPG/PNG image", "error");
      return;
    }
  
    // Validate email format
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail_id)) {
      swal("Error", "Invalid email format", "error");
      return;
    }
  
    // Validate contact number
    if (!/^\d{10,}$/.test(contact_number)) {
      swal("Error", "Please enter a valid contact number (minimum 10 digits)", "error");
      return;
    }
  
    const formData = new FormData();
    formData.append("caption", caption);
    formData.append("description", description);
    formData.append("sort_order", sortOrder);
    formData.append("name", name);
    formData.append("designation", designation);
    formData.append("jurisdiction", jurisdiction);
    formData.append("contact_number", contact_number);
    formData.append("mail_id", mail_id);
    
    if (fileUpload) {
      formData.append("image_upload", fileUpload);
    }
    
    // Optional fields
    formData.append("is_active", "1");
    formData.append("created_by", "admin");
  
    try {
      let response;
      if (isEditing) {
        response = await APIService.put(formData, `cgrf/${cgrfId}`);
      } else {
        response = await APIService.post(formData, 'cgrf');
      }
  
      if (response.success || response.message) {
        swal("Success", response.message || "Operation successful", "success");
        resetForm();
        fetchCgrf();
      } else {
        swal("Failed", response.message || "Operation failed", "error");
      }
    } catch (error) {
      console.error("API Error:", error);
      const errorMessage = error.response?.data?.message || "An error occurred";
      swal("Error", errorMessage, "error");
    }
  };

  const resetForm = () => {
    setCaption("");
    setDescription("");
    setSortOrder("");
    setName("");
    setDesignation("");
    setJurisdiction("");
    setContactNumber("");
    setMailId("");
    setFileUpload(null);
    setCgrfId("");
    setIsEditing(false);
  };


  return (
    <div>
      <h2 className="organisation-head">CGRF</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Caption*</p>
            <TextField
              name="caption"
              label="Caption"
              variant="outlined"
              fullWidth
              required
              value={caption}
              onChange={e => setCaption(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Sort Order</p>
            <TextField
              name="sort_order"
              label="Sort Order"
              variant="outlined"
              fullWidth
              type="number"
              value={sortOrder}
              onChange={e => setSortOrder(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Name*</p>
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              required
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Designation*</p>
            <TextField
              name="designation"
              label="Designation"
              variant="outlined"
              fullWidth
              required
              value={designation}
              onChange={e => setDesignation(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Jurisdiction*</p>
            <TextField
              name="jurisdiction"
              label="Jurisdiction"
              variant="outlined"
              fullWidth
              required
              value={jurisdiction}
              onChange={e => setJurisdiction(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Contact Number*</p>
            <TextField
              name="contact_number"
              label="Contact Number"
              variant="outlined"
              fullWidth
              required
              type="tel"
              value={contact_number}
              onChange={e => setContactNumber(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Email*</p>
            <TextField
              name="mail_id"
              label="Email"
              variant="outlined"
              fullWidth
              required
              type="email"
              value={mail_id}
              onChange={e => setMailId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload Image{!isEditing && '*'}</p>
            <input
              name="image_upload"
              type="file"
              accept="image/jpeg, image/png"
              onChange={handleFileChange}
              required={!isEditing}
              style={{ display: 'block' }}
            />
            {isEditing && <small>Leave empty to keep current image</small>}
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={12} lg={12}>
            <p id="labels">Description</p>
            <TextField
              fullWidth
              name="description"
              label="Description"
              multiline
              rows={3}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>

        <Button style={{ marginTop: 20 }} variant="primary" type="submit">
          {isEditing ? "Update" : "Submit"}
        </Button>{' '}
        {isEditing && (
          <Button style={{ marginTop: 20 }} variant="secondary" onClick={resetForm}>
            Cancel
          </Button>
        )}
      </form>

      <Table striped bordered hover variant="light" style={{ marginTop: 20 }}>
        <thead>
          <tr>
            <th>Sl No</th>
            <th style={{width:'10%'}}>Caption</th>
            <th style={{width:'20%'}}>Description</th>
            <th style={{width:'10%'}}>Name</th>
            <th style={{width:'10%'}}>Designation</th>
            <th style={{width:'10%'}}>Jurisdiction</th>
            <th style={{width:'10%'}}>Contact</th>
            <th style={{width:'10%'}}>Email</th>
            <th style={{width:'5%'}}>Sort Order</th>
            <th style={{width:'5%'}}>Image</th>
            <th style={{width:'10%'}}>Actions</th>
            <th >Move Up</th>
            <th >Move Down</th>

          </tr>
        </thead>
        <tbody>
          {cgrf.map((cg, index) => (
            <tr key={cg.id}>
              <td>{index + 1}</td>
              <td style={{width:'10%'}}>{cg.caption}</td>
              <td style={{width:'20%'}}>{cg.description}</td>
              <td style={{width:'10%'}}>{cg.name}</td>
              <td style={{width:'10%'}}>{cg.designation}</td>
              <td style={{width:'10%'}}>{cg.jurisdiction}</td>
              <td style={{width:'10%'}}>{cg.contact_number }</td>
              <td style={{width:'10%'}}>{cg.mail_id}</td>
              <td style={{width:'5%'}}>{cg.sort_order}</td>
              <td style={{width:'5%'}}>
                {cg.image_url ? (
                  <img 
                    src={cg.image_url} 
                    alt="CGRF" 
                    style={{ maxWidth: '50px', maxHeight: '50px' }} 
                  />
                ) : cg.image ? (
                  <img 
                    src={cg.image} 
                    alt="CGRF" 
                    style={{ maxWidth: '50px', maxHeight: '50px' }} 
                  />
                ) : 'N/A'}
              </td>
              <td style={{width:'10%'}}>
                <button className='tablebutton' onClick={() => handleEdit(cg)}>
                  Edit
                </button>
                <button 
                  className='tablebutton' 
                  style={{ marginLeft: '5px' }}
                  onClick={() => handleDelete(cg.id)}
                >
                  Delete
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("up", cg.id)}
                >
                  <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("down", cg.id)}
                >
                  <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

// import React, { useState, useEffect } from 'react';
// import { Grid, TextField } from '@mui/material';
// import Button from 'react-bootstrap/Button';
// import APIService from '../../../../APIService';
// import swal from 'sweetalert';
// import { Table } from 'react-bootstrap';

// export default function CGRF() {
//   const [cgrf, setCgrf] = useState([]);
//   const [caption, setCaption] = useState("");
//   const [description, setDescription] = useState("");
//   const [sortOrder, setSortOrder] = useState("");
//   const [name, setName] = useState("");
//   const [designation, setDesignation] = useState("");
//   const [jurisdiction, setJurisdiction] = useState("");
//   const [contactNumber, setContactNumber] = useState("");
//   const [mailId, setMailId] = useState("");
//   const [fileUpload, setFileUpload] = useState(null);
//   const [cgrfId, setCgrfId] = useState("");
//   const [isEditing, setIsEditing] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     fetchCgrf();
//   }, []);

//   const fetchCgrf = () => {
//     setIsLoading(true);
//     APIService.get('cgrf')
//       .then((response) => {
//         setCgrf(response.data);
//       })
//       .catch(error => {
//         console.error("Error fetching CGRF data:", error);
//         swal("Error", "Failed to fetch CGRF data. Please try again.", "error");
//       })
//       .finally(() => setIsLoading(false));
//   };

//   const handleFileChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       setFileUpload(e.target.files[0]);
//     }
//   };

//   const handleEdit = (item) => {
//     setIsEditing(true);
//     setCgrfId(item.id);
//     setCaption(item.caption || "");
//     setDescription(item.description || "");
//     setSortOrder(item.sort_order || "");
//     setName(item.name || "");
//     setDesignation(item.designation || "");
//     setJurisdiction(item.jurisdiction || "");
//     setContactNumber(item.mobile || "");
//     setMailId(item.email || "");
//   };

//   const handleDelete = (itemId) => {
//     swal({
//       title: "Are you sure?",
//       text: "You want to delete this item?",
//       icon: "warning",
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         APIService.delete(`cgrf/${itemId}`)
//           .then((response) => {
//             swal("Success", response.data?.message || "Item deleted successfully", "success", {
//               buttons: false,
//               timer: 2000,
//             }).then(() => {
//               fetchCgrf();
//             });
//           })
//           .catch(error => {
//             console.error("Delete error:", error);
//             swal("Error", error.response?.data?.message || "Failed to delete item", "error");
//           });
//       }
//     });
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
  
//     // Validate required fields
//     const errors = [];
//     if (!caption) errors.push("Caption is required");
//     if (!description) errors.push("Description is required");
//     if (sortOrder === "") errors.push("Sort Order is required");
//     if (!fileUpload && !isEditing) errors.push("Image upload is required");
//     if (fileUpload && !fileUpload.name.match(/\.(jpg|jpeg|png)$/i)) {
//       errors.push("Please upload a valid JPG/PNG image");
//     }
//     if (mailId && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mailId)) {
//       errors.push("Invalid email format");
//     }
  
//     if (errors.length > 0) {
//       swal("Validation Error", errors.join("\n"), "error");
//       setIsLoading(false);
//       return;
//     }
  
//     const formData = new FormData();
//     // Required fields
//     formData.append("caption", caption);
//     formData.append("description", description);
//     formData.append("sort_order", sortOrder);
    
//     // Optional fields (only append if they have values)
//     if (name) formData.append("name", name);
//     if (designation) formData.append("designation", designation);
//     if (jurisdiction) formData.append("jurisdiction", jurisdiction);
//     if (contactNumber) formData.append("mobile", contactNumber);
//     if (mailId) formData.append("email", mailId);
    
//     // Handle image upload - only append if exists (for update) or required (for create)
//     if (fileUpload) {
//       formData.append("image", fileUpload);
//     } else if (isEditing) {
//       // For updates, if no new image is provided, explicitly tell backend to keep existing
//       formData.append("keep_existing_image", "1");
//     }
  
//     try {
//       const endpoint = isEditing ? `cgrf/${cgrfId}` : 'cgrf';
//       const response = isEditing 
//         ? await APIService.put(formData, endpoint)
//         : await APIService.post(formData, endpoint);
  
//       // Check for validation errors in response
//       if (response.status === 422) {
//         const errorMessages = Object.values(response.errors).flat().join("\n");
//         swal("Validation Error", errorMessages, "error");
//         return;
//       }
  
//       if (response.success || response.message) {
//         swal("Success", response.message || "Operation successful", "success");
//         resetForm();
//         fetchCgrf();
//       } else {
//         throw new Error(response.message || "Operation failed");
//       }
//     } catch (error) {
//       console.error("API Error:", error);
      
//       let errorMessage = "Operation failed. Please try again.";
//       if (error.response?.data?.errors) {
//         // Handle Laravel-style validation errors
//         errorMessage = Object.values(error.response.data.errors)
//                            .flat()
//                            .join("\n");
//       } else if (error.message) {
//         errorMessage = error.message;
//       }
      
//       swal("Error", errorMessage, "error");
//     } finally {
//       setIsLoading(false);
//     }
//   };




//   const resetForm = () => {
//     setCaption("");
//     setDescription("");
//     setSortOrder("");
//     setName("");
//     setDesignation("");
//     setJurisdiction("");
//     setContactNumber("");
//     setMailId("");
//     setFileUpload(null);
//     setCgrfId("");
//     setIsEditing(false);
//   };

//   return (
//     <div>
//       <h2 className="organisation-head">CGRF</h2>
//       <form noValidate onSubmit={handleSubmit}>
//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Caption*</p>
//             <TextField
//               name="caption"
//               label="Caption"
//               variant="outlined"
//               fullWidth
//               required
//               value={caption}
//               onChange={e => setCaption(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Sort Order*</p>
//             <TextField
//               name="sort_order"
//               label="Sort Order"
//               variant="outlined"
//               fullWidth
//               type="number"
//               required
//               value={sortOrder}
//               onChange={e => setSortOrder(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Name</p>
//             <TextField
//               name="name"
//               label="Name"
//               variant="outlined"
//               fullWidth
//               value={name}
//               onChange={e => setName(e.target.value)}
//             />
//           </Grid>
//         </Grid>

//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Designation</p>
//             <TextField
//               name="designation"
//               label="Designation"
//               variant="outlined"
//               fullWidth
//               value={designation}
//               onChange={e => setDesignation(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Jurisdiction</p>
//             <TextField
//               name="jurisdiction"
//               label="Jurisdiction"
//               variant="outlined"
//               fullWidth
//               value={jurisdiction}
//               onChange={e => setJurisdiction(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Contact Number</p>
//             <TextField
//               name="contact_number"
//               label="Contact Number"
//               variant="outlined"
//               fullWidth
//               value={contactNumber}
//               onChange={e => setContactNumber(e.target.value)}
//             />
//           </Grid>
//         </Grid>

//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Email</p>
//             <TextField
//               name="mail_id"
//               label="Email"
//               variant="outlined"
//               fullWidth
//               type="email"
//               value={mailId}
//               onChange={e => setMailId(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">{isEditing ? "Update Image (Leave empty to keep current)" : "Upload Image*"}</p>
//             <TextField
//               name="upload-photo"
//               type="file"
//               accept="image/jpeg, image/png"
//               onChange={handleFileChange}
//               required={!isEditing}
//             />
//           </Grid>
//         </Grid>

//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={12} md={8}>
//             <p id="labels">Description*</p>
//             <TextField
//               fullWidth
//               id="outlined-multiline-static"
//               label="Description"
//               multiline
//               rows={3}
//               required
//               value={description}
//               onChange={e => setDescription(e.target.value)}
//             />
//           </Grid>
//         </Grid>

//         <Button 
//           style={{ marginTop: 20 }} 
//           variant="primary" 
//           type="submit"
//           disabled={isLoading}
//         >
//           {isLoading ? 'Processing...' : isEditing ? "Update" : "Submit"}
//         </Button>{' '}
//         {isEditing && (
//           <Button 
//             style={{ marginTop: 20 }} 
//             variant="secondary" 
//             onClick={resetForm}
//             disabled={isLoading}
//           >
//             Cancel
//           </Button>
//         )}
//       </form>

//       {isLoading ? (
//         <div style={{ textAlign: 'center', marginTop: 20 }}>Loading data...</div>
//       ) : (
//         <Table striped bordered hover variant="light" style={{ marginTop: 20 }}>
//           <thead>
//             <tr>
//               <th>Sl No</th>
//               <th>Caption</th>
//               <th>Description</th>
//               <th>Name</th>
//               <th>Designation</th>
//               <th>Jurisdiction</th>
//               <th>Contact</th>
//               <th>Email</th>
//               <th>Sort Order</th>
//               <th>Image</th>
//               <th>Edit</th>
//               <th>Delete</th>
//             </tr>
//           </thead>
//           <tbody>
//             {cgrf.map((cg, index) => (
//               <tr key={cg.id}>
//                 <td>{index + 1}</td>
//                 <td>{cg.caption}</td>
//                 <td>{cg.description}</td>
//                 <td>{cg.name || "-"}</td>
//                 <td>{cg.designation || "-"}</td>
//                 <td>{cg.jurisdiction || "-"}</td>
//                 <td>{cg.mobile || "-"}</td>
//                 <td>{cg.email || "-"}</td>
//                 <td>{cg.sort_order}</td>
//                 <td>
//                   {cg.image && (
//                     <a href={cg.image} target="_blank" rel="noopener noreferrer">
//                       View
//                     </a>
//                   )}
//                 </td>
//                 <td>
//                   <button 
//                     className='tablebutton'
//                     onClick={() => handleEdit(cg)}
//                     disabled={isLoading}
//                   >
//                     Edit
//                   </button>
//                 </td>
//                 <td>
//                   <button 
//                     className='tablebutton'
//                     onClick={() => handleDelete(cg.id)}
//                     disabled={isLoading}
//                   >
//                     Delete
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//       )}
//     </div>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { Grid, TextField } from '@mui/material';
// import Button from 'react-bootstrap/Button';
// import APIService from '../../../../APIService';
// import swal from 'sweetalert';
// import { Table } from 'react-bootstrap';

// export default function CGRF() {
//   const [cgrf, setCgrf] = useState([]);
//   const [formData, setFormData] = useState({
//     caption: "",
//     description: "",
//     sort_order: "",
//     name: "",
//     designation: "",
//     jurisdiction: "",
//     contact_number: "",
//     mail_id: "",
//     image_upload: ""
//   });
//   const [cgrfId, setCgrfId] = useState("");
//   const [isEditing, setIsEditing] = useState(false);

//   useEffect(() => {
//     fetchCgrf();
//   }, []);

//   const fetchCgrf = () => {
//     APIService.get('cgrf').then((response) => {
//       setCgrf(response.data);
//     });
//   };

//   const handleFileChange = (e) => {
//     if (e.target.files) {
//       setFormData({ ...formData, image_upload: e.target.files[0] });
//     }
//   };

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleEdit = (item) => {
//     setIsEditing(true);
//     setCgrfId(item.id);
//     setFormData({
//       caption: item.caption,
//       description: item.description,
//       sort_order: item.sort_order,
//       name: item.name || "",
//       designation: item.designation || "",
//       jurisdiction: item.jurisdiction || "",
//       contact_number: item.phone || item.mobile || "",
//       mail_id: item.email || "",
//       image_upload: "",
//     });
//   };

//   const handleDelete = (itemId) => {
//     swal({
//       title: "Are you sure?",
//       text: "You want to delete this item?",
//       icon: "warning",
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         APIService.delete(`cgrf/${itemId}`).then((response) => {
//           swal("Success", response.message, "success", {
//             buttons: false,
//             timer: 2000,
//           }).then(() => {
//             fetchCgrf();
//           });
//         });
//       }
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     const formDataToSend = new FormData();
    
//     // Append all text fields
//     formDataToSend.append("caption", formData.caption);
//     formDataToSend.append("description", formData.description);
//     formDataToSend.append("sort_order", formData.sort_order);
//     formDataToSend.append("name", formData.name);
//     formDataToSend.append("designation", formData.designation);
//     formDataToSend.append("jurisdiction", formData.jurisdiction);
//     formDataToSend.append("contact_number", formData.contact_number);
//     formDataToSend.append("mail_id", formData.mail_id);
    
//     // Append image if exists
//     if (formData.image_upload) {
//       formDataToSend.append("image_upload", formData.image_upload);
//     }
  
//     try {
//       let response;
//       if (isEditing) {
//         response = await APIService.put(formDataToSend, `cgrf/${cgrfId}`);
//       } else {
//         response = await APIService.post(formDataToSend, 'cgrf');
//       }
  
//       if (response.data.success) {
//         swal("Success", response.data.message, "success", {
//           buttons: false,
//           timer: 2000,
//         }).then(() => {
//           resetForm();
//           fetchCgrf();
//         });
//       } else {
//         swal("Failed", response.data.message, "error");
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       swal("Error", error.response?.data?.message || "An error occurred", "error");
//     }
//   };
//   const resetForm = () => {
//     setFormData({
//       caption: "",
//       description: "",
//       sort_order: "",
//       name: "",
//       designation: "",
//       jurisdiction: "",
//       contact_number: "",
//       mail_id: "",
//       image_upload: ""
//     });
//     setCgrfId("");
//     setIsEditing(false);
//   };

//   return (
//     <div>
//       <h2 className="organisation-head">CGRF</h2>
//       <form noValidate onSubmit={handleSubmit}>
//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           {Object.keys(formData).map((key) => (
//             key !== "image_upload" && (
//               <Grid key={key} item xs={12} sm={6} lg={4}>
//                 <p id="labels">{key.replace('_', ' ').toUpperCase()}</p>
//                 <TextField
//                   name={key}
//                   label={key.replace('_', ' ').toUpperCase()}
//                   variant="outlined"
//                   fullWidth
//                   value={formData[key]}
//                   onChange={handleChange}
//                 />
//               </Grid>
//             )
//           ))}
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Upload Image</p>
//             <TextField
//               name="upload-photo"
//               type="file"
//               onChange={handleFileChange}
//             />
//           </Grid>
//         </Grid>
//         <Button style={{ marginTop: 20 }} variant="primary" type="submit">
//           {isEditing ? "Update" : "Submit"}
//         </Button>{' '}
//         {isEditing && (
//           <Button style={{ marginTop: 20 }} variant="secondary" onClick={resetForm}>
//             Cancel
//           </Button>
//         )}
//       </form>
//       <Table striped bordered hover variant="light">
//         <thead>
//           <tr>
//             <th>Sl No</th>
//             <th>Caption</th>
//             <th>Description</th>
//             <th>Sort Order</th>
//             <th>Name</th>
//             <th>Designation</th>
//             <th>Jurisdiction</th>
//             <th>Contact</th>
//             <th>Email</th>
//             <th>Image</th>
//             <th>Edit</th>
//             <th>Delete</th>
//           </tr>
//         </thead>
//         <tbody>
//           {cgrf.map((cg, index) => (
//             <tr key={cg.id}>
//               <td>{index + 1}</td>
//               <td>{cg.caption}</td>
//               <td>{cg.description}</td>
//               <td>{cg.sort_order}</td>
//               <td>{cg.name}</td>
//               <td>{cg.designation}</td>
//               <td>{cg.jurisdiction}</td>
//               <td>{cg.phone || cg.mobile}</td>
//               <td>{cg.email}</td>
//               <td><img src={cg.image} alt="uploaded" width={50} /></td>
//               <td><button className='tablebutton' onClick={() => handleEdit(cg)}>Edit</button></td>
//               <td><button className='tablebutton' onClick={() => handleDelete(cg.id)}>Delete</button></td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// }
