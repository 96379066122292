// import React, { useState, useEffect } from 'react';
// import Table from 'react-bootstrap/Table';
// import Button from 'react-bootstrap/Button';
// import swal from 'sweetalert';
// import * as XLSX from 'xlsx'; // Import the xlsx library

// export const FileAComplaint = () => {
 
  

//  // Fetch complaints data from the backend (commented out for now)
//   useEffect(() => {
//     fetchComplaints();
//   }, []);

//   const fetchComplaints = async () => {
//     try {
//       const response = await APIService.get('feedback'); // Adjust the API endpoint as needed
//       if (response.success) {
//         setComplaints(response.data);
//       } else {
//         swal("Error", "Failed to fetch complaints", "error");
//       }
//     } catch (error) {
//       console.error("Error fetching complaints:", error);
//       swal("Error", "An error occurred while fetching complaints", "error");
//     }
//   };

//   // Handle delete functionality
//   const handleDelete = (id) => {
//     swal({
//       title: "Are you sure?",
//       text: "You want to delete this complaint?",
//       icon: "warning",
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         // Filter out the deleted complaint
//         const updatedComplaints = complaints.filter((complaint) => complaint.id !== id);
//         setComplaints(updatedComplaints);
//         swal("Success", "Complaint deleted successfully", "success");
//       }
//     });
//   };

//   // Function to export data to Excel
//   const exportToExcel = () => {
//     // Map the complaints data to a format suitable for Excel
//     const data = complaints.map((complaint, index) => ({
//       "Sl No": index + 1,
//       "Full Name": complaint.fullName,
//       "Email": complaint.email,
//       "Phone": complaint.phone,
//       "Address": complaint.address,
//       "Street": complaint.street,
//       "City": complaint.city,
//       "District": complaint.district,
//       "PIN Code": complaint.pinCode,
//       "Type": complaint.type === "1" ? "Complaint" :
//               complaint.type === "2" ? "Petition" :
//               complaint.type === "3" ? "Feedback" :
//               complaint.type === "4" ? "Suggestion" : "Others",
//       "Other Type": complaint.otherType || "N/A",
//       "Remarks": complaint.remarks,
     
//     }));

//     // Create a new worksheet
//     const worksheet = XLSX.utils.json_to_sheet(data);

//     // Create a new workbook
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Complaints");

//     // Generate Excel file and trigger download
//     XLSX.writeFile(workbook, "Complaints.xlsx");
//   };

//   return (
//     <div>
//       <h2 className="organisation-head">File a Complaint</h2>
//       <Button variant="success" onClick={exportToExcel} style={{ marginBottom: '20px' }}>
//         Download as Excel
//       </Button>
//       <Table striped bordered hover variant="light">
//         <thead>
//           <tr>
//             <th>Sl No</th>
//             <th>Full Name</th>
//             <th>Email</th>
//             <th>Phone</th>
//             <th>Address</th>
//             <th>Street</th>
//             <th>City</th>
//             <th>District</th>
//             <th>PIN Code</th>
//             <th>Type</th>
//             <th>Other Type</th>
//             <th>Remarks</th>
          
//             <th>Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           {complaints.map((complaint, index) => (
//             <tr key={complaint.id}>
//               <td>{index + 1}</td>
//               <td>{complaint.fullName}</td>
//               <td>{complaint.email}</td>
//               <td>{complaint.phone}</td>
//               <td>{complaint.address}</td>
//               <td>{complaint.street}</td>
//               <td>{complaint.city}</td>
//               <td>{complaint.district}</td>
//               <td>{complaint.pinCode}</td>
//               <td>
//                 {complaint.type === "1" && "Complaint"}
//                 {complaint.type === "2" && "Petition"}
//                 {complaint.type === "3" && "Feedback"}
//                 {complaint.type === "4" && "Suggestion"}
//                 {complaint.type === "5" && "Others"}
//               </td>
//               <td>{complaint.otherType}</td>
//               <td>{complaint.remarks}</td>
              
//               <td>
//                 <Button variant="danger" onClick={() => handleDelete(complaint.id)}>
//                   Delete
//                 </Button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';
import * as XLSX from 'xlsx';
import APIService from "../../../../APIService";

export const FileAComplaint = () => {
  const [complaints, setComplaints] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch complaints data from the backend with cache busting
  useEffect(() => {
    fetchComplaints();
  }, []);

  const fetchComplaints = async () => {
    setIsLoading(true);
    try {
      const response = await APIService.get(`feedback?timestamp=${Date.now()}`);
      console.log("Full fetch response:", response); // Add this line
      setComplaints(Array.isArray(response) ? response : response.data || []);
    } catch (error) {
      console.error("Fetch Error:", error);
      swal("Error", "Failed to load complaints. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleDelete = async (id) => {
    if (window.confirm("Confirm deletion?")) {
      setIsLoading(true);
      try {
        // Initial delete request
        const deleteResponse = await APIService.delete(`feedback/${id}`);
        console.log("Initial delete response:", deleteResponse);
  
        // First verification request
        const verify1 = await APIService.get(`feedback?timestamp=${Date.now()}`);
        console.log("Immediate verification:", verify1.data);
  
        // Wait a moment if using eventual consistency
        await new Promise(resolve => setTimeout(resolve, 500));
  
        // Second verification request
        const verify2 = await APIService.get(`feedback?timestamp=${Date.now()}`);
        console.log("Delayed verification:", verify2.data);
  
        // Check if ID still exists
        const stillExists = verify2.data.some(item => item.id === id);
        if (stillExists) {
          throw new Error("Delete verification failed - record still exists");
        }
  
        // Update UI only after successful verification
        setComplaints(verify2.data);
        swal("Success", "Permanently deleted", "success");
  
      } catch (error) {
        console.error("Full deletion error:", {
          message: error.message,
          stack: error.stack,
          response: error.response?.data
        });
        swal("Error", "Deletion not persisted. Contact support.", "error");
        await fetchComplaints();
      } finally {
        setIsLoading(false);
      }
    }
  };
  // Function to export data to Excel
  const exportToExcel = () => {
    const data = complaints.map((complaint, index) => ({
      "Sl No": index + 1,
      "Full Name": complaint.fullname,
      "Email": complaint.email,
      "Phone": complaint.phone,
      "Address": complaint.address,
      "Street": complaint.street,
      "City": complaint.city,
      "District": complaint.district,
      "PIN Code": complaint.pincode,
      "Complaint Nature": complaint.complaint_nature,
      "Complaint Type": complaint.complaint_type || "N/A",
      "Remarks": complaint.remarks,
      "Created At": new Date(complaint.created_at).toLocaleString(),
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Complaints");
    XLSX.writeFile(workbook, "Complaints.xlsx");
  };

  return (
    <div>
      <h2 className="organisation-head">File a Complaint</h2>
      <div className="mb-3 d-flex justify-content-between">
        <Button variant="success" onClick={exportToExcel} disabled={isLoading || complaints.length === 0}>
          Download as Excel
        </Button>
        {/* <Button variant="primary" onClick={fetchComplaints} disabled={isLoading}>
          {isLoading ? 'Refreshing...' : 'Refresh Data'}
        </Button> */}
      </div>
      
      {isLoading ? (
        <div className="text-center my-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p>Loading complaints...</p>
        </div>
      ) : complaints.length === 0 ? (
        <div className="alert alert-info">No complaints found</div>
      ) : (
        <div className="table-responsive">
          <Table striped bordered hover variant="light">
            <thead>
              <tr>
                <th>Sl No</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Street</th>
                <th>City</th>
                <th>District</th>
                <th>PIN Code</th>
                <th>Complaint Nature</th>
                <th>Complaint Type</th>
                <th>Remarks</th>
                <th>Created At</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {complaints.map((complaint, index) => (
                <tr key={complaint.id}>
                  <td>{index + 1}</td>
                  <td>{complaint.fullname}</td>
                  <td>{complaint.email}</td>
                  <td>{complaint.phone}</td>
                  <td>{complaint.address}</td>
                  <td>{complaint.street}</td>
                  <td>{complaint.city}</td>
                  <td>{complaint.district}</td>
                  <td>{complaint.pincode}</td>
                  <td>{complaint.complaint_nature}</td>
                  <td>{complaint.complaint_type || "N/A"}</td>
                  <td>{complaint.remarks}</td>
                  <td>{new Date(complaint.created_at).toLocaleString()}</td>
                  {/* <td>
                    <Button 
                      variant="danger" 
                      onClick={() => handleDelete(complaint.id)}
                      disabled={isLoading}
                    >
                      Delete
                    </Button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};