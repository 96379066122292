// import React, { useState, useEffect } from 'react';
// import Table from 'react-bootstrap/Table';
// import { Grid, TextField } from '@mui/material';
// import swal from 'sweetalert';
// import { LoadingButton } from '@mui/lab';

// export default function Internship() {

//   const [internships, setInternships] = useState([]);
//   const [date, setDate] = useState("");
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [fileUpload, setFileUpload] = useState("");
//   const [applyLink, setApplyLink] = useState("");

//   const [internshipId, setInternshipId] = useState("");
//   const [isEditing, setIsEditing] = useState(false);

//   // Dummy data for internships
//   const dummyInternships = [
//     { id: 1, job_title: 'Software Development Intern', description: 'Work on cutting-edge software projects.', last_date: '2023-12-31', file_upload: '/path/to/file1.pdf', apply_link: 'https://example.com/apply/1' },
//     { id: 2, job_title: 'Data Science Intern', description: 'Analyze large datasets and build predictive models.', last_date: '2023-11-15', file_upload: '/path/to/file2.pdf', apply_link: 'https://example.com/apply/2' },
//     { id: 3, job_title: 'Marketing Intern', description: 'Assist in digital marketing campaigns.', last_date: '2023-10-20', file_upload: '/path/to/file3.pdf', apply_link: 'https://example.com/apply/3' },
//     { id: 4, job_title: 'Graphic Design Intern', description: 'Create visual content for social media and websites.', last_date: '2023-09-25', file_upload: '/path/to/file4.pdf', apply_link: 'https://example.com/apply/4' },
//   ];

//   useEffect(() => {
//     // Simulate API call with dummy data
//     setInternships(dummyInternships);
//   }, []);

//   const handleFileChange = (e) => {
//     if (e.target.files) {
//       setFileUpload(e.target.files[0]);
//     }
//   };

//   const changePosition = (direction, itemId) => {
//     // Simulate position change logic
//     const updatedInternships = [...internships];
//     const index = updatedInternships.findIndex(item => item.id === itemId);
//     const targetIndex = direction === "up" ? index - 1 : index + 1;

//     if (targetIndex >= 0 && targetIndex < updatedInternships.length) {
//       [updatedInternships[index], updatedInternships[targetIndex]] = [updatedInternships[targetIndex], updatedInternships[index]];
//       setInternships(updatedInternships);
//     }
//   };

//   const handleEdit = (itemId, title, date, description, applyLink) => {
//     setInternshipId(itemId);
//     setIsEditing(true);
//     setTitle(title);
//     setDate(date);
//     setDescription(description);
//     setApplyLink(applyLink);
//   };

//   const handleDelete = (itemId) => {
//     swal({
//       title: "Are you sure?",
//       text: "You want to delete this item?",
//       icon: "warning",
//       dangerMode: true,
//     }).then(willDelete => {
//       if (willDelete) {
//         const updatedInternships = internships.filter(item => item.id !== itemId);
//         setInternships(updatedInternships);
//         swal("Success", "Internship deleted successfully!", "success", {
//           buttons: false,
//           timer: 2000,
//         });
//       }
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const newInternship = {
//       id: isEditing ? internshipId : internships.length + 1,
//       job_title: title,
//       description: description,
//       last_date: date,
//       file_upload: fileUpload.name || "dummy_file.pdf",
//       apply_link: applyLink,
//     };

//     if (isEditing) {
//       const updatedInternships = internships.map(item =>
//         item.id === internshipId ? newInternship : item
//       );
//       setInternships(updatedInternships);
//       swal("Success", "Internship updated successfully!", "success", {
//         buttons: false,
//         timer: 2000,
//       });
//     } else {
//       setInternships([...internships, newInternship]);
//       swal("Success", "Internship added successfully!", "success", {
//         buttons: false,
//         timer: 2000,
//       });
//     }

//     // Reset form fields
//     setIsEditing(false);
//     setTitle("");
//     setDate("");
//     setDescription("");
//     setFileUpload("");
//     setApplyLink("");
//   };

//   return (
//     <div>
//       <h2>Internships</h2>
//       <form className="login-form" noValidate onSubmit={handleSubmit}>
//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Title</p>
//             <TextField
//               name="title"
//               label="Title"
//               variant="outlined"
//               fullWidth
//               value={title}
//               onChange={e => setTitle(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Date</p>
//             <TextField
//               type='date'
//               name="number"
//               variant="outlined"
//               fullWidth
//               value={date}
//               onChange={e => setDate(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Upload File</p>
//             <TextField
//               name="upload-photo"
//               type="file"
//               onChange={handleFileChange}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={6}>
//             <p id="labels">Description</p>
//             <TextField
//               fullWidth
//               value={description}
//               onChange={e => setDescription(e.target.value)}
//               id="outlined-multiline-static"
//               label="Description"
//               multiline
              
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={6}>
//             <p id="labels">Apply Link</p>
//             <TextField
//               fullWidth
//               value={applyLink}
//               onChange={e => setApplyLink(e.target.value)}
//               label="Apply Link"
//               variant="outlined"
//             />
//           </Grid>
//         </Grid>
//         <div className='login-btn' style={{ marginTop: 30 }}>
//           <LoadingButton type="submit" variant="contained" id='login-btn'>
//             Submit
//           </LoadingButton>
//         </div>
//       </form>
//       <Table striped bordered hover variant="light">
//         <thead>
//           <tr>
//             <th>Title</th>
//             <th>Description</th>
//             <th>Last Date</th>
//             <th>Edit</th>
//             <th>Delete</th>
//             <th>Move Up</th>
//             <th>Move Down</th>
//           </tr>
//         </thead>
//         <tbody>
//           {internships.map(internship => (
//             <tr key={internship.id}>
//               <td>{internship.job_title}</td>
//               <td>{internship.description}</td>
//               <td>{internship.last_date}</td>
//               <td>
//                 <button
//                   className='tablebutton'
//                   onClick={() => handleEdit(internship.id, internship.job_title, internship.last_date, internship.description, internship.apply_link)}
//                 >
//                   Edit
//                 </button>
//               </td>
//               <td>
//                 <button
//                   className='tablebutton'
//                   onClick={() => handleDelete(internship.id)}
//                 >
//                   Delete
//                 </button>
//               </td>
//               <td>
//                 <button
//                   className='move-btn'
//                   onClick={() => changePosition("up", internship.id)}
//                 >
//                   <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
//                 </button>
//               </td>
//               <td>
//                 <button
//                   className='move-btn'
//                   onClick={() => changePosition("down", internship.id)}
//                 >
//                   <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// }
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Grid, TextField } from '@mui/material';
import swal from 'sweetalert';
import { LoadingButton } from '@mui/lab';
import APIService from '../../../../APIService';

export default function Internship() {
  const [internships, setInternships] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    date: '',
    description: '',
    file_upload: null,
    url: '',
    position: 0
  });
  const [internshipId, setInternshipId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchInternships();
  }, []);

  const fetchInternships = () => {
    APIService.get('internship').then((response) => {
      if (response && response.data) { // Changed from response.internship to response.data
        setInternships(response.data);
        // Set position to the next available number when adding new items
        if (response.data.length > 0) {
          const maxPosition = Math.max(...response.data.map(i => i.position || 0));
          setFormData(prev => ({
            ...prev,
            position: maxPosition + 1
          }));
        }
      }
    }).catch(error => {
      console.error("Error fetching internships:", error);
      swal("Error", "Failed to fetch internships", "error");
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file_upload: e.target.files[0]
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const changePosition = (direction, itemId) => {
    APIService.post([], `change-position/${direction}/internship/${itemId}`)
      .then((response) => {
        if (response.success) {
          fetchInternships();
        }
      })
      .catch(error => {
        console.error("Error changing position:", error);
        swal("Error", "Failed to change position", "error");
      });
  };

  const handleEdit = (internship) => {
    setInternshipId(internship.id);
    setIsEditing(true);
    setFormData({
      title: internship.title,
      date: internship.date,
      description: internship.description,
      url: internship.url,
      file_upload: null,
      position: internship.position || 0
    });
    setErrors({});
  };

  const handleDelete = (itemId) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        APIService.delete(`internship/${itemId}`)
          .then(response => {
            swal("Success", response.message, "success", {
              buttons: false,
              timer: 2000,
            });
            fetchInternships();
          })
          .catch(error => {
            console.error("Error deleting internship:", error);
            swal("Error", "Failed to delete internship", "error");
          });
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    const data = new FormData();
    data.append("title", formData.title);
    data.append("description", formData.description);
    data.append("date", formData.date);
    data.append("url", formData.url);
    data.append("position", formData.position);
    if (formData.file_upload) {
      data.append("file_upload", formData.file_upload);
    }

    try {
      const response = isEditing 
        ? await APIService.put(data, `internship/${internshipId}`)
        : await APIService.post(data, 'internship');

      if (response.success) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        });
        resetForm();
        fetchInternships();
      } else {
        if (response.errors) {
          setErrors(response.errors);
        }
        swal("Error", response.message || "Operation failed", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      swal("Error", "An unexpected error occurred", "error");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      title: '',
      date: '',
      description: '',
      file_upload: null,
      url: '',
      position: internships.length > 0 
        ? Math.max(...internships.map(i => i.position || 0)) + 1 
        : 0
    });
    setInternshipId("");
    setIsEditing(false);
    setErrors({});
  };

  return (
    <div>
      <h2>Internships</h2>
      <form className="login-form" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Title</p>
            <TextField
              name="title"
              label="Title"
              variant="outlined"
              fullWidth
              value={formData.title}
              onChange={handleInputChange}
              error={!!errors.title}
              helperText={errors.title}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Last Date</p>
            <TextField
              type='date'
              name="date"
              variant="outlined"
              fullWidth
              value={formData.date}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              error={!!errors.date}
              helperText={errors.date}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload File</p>
            <TextField
              name="file_upload"
              type="file"
              onChange={handleFileChange}
              InputLabelProps={{ shrink: true }}
              error={!!errors.file_upload}
              helperText={errors.file_upload}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={6}>
            <p id="labels">Description</p>
            <TextField
              fullWidth
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              label="Description"
              multiline
              rows={4}
              error={!!errors.description}
              helperText={errors.description}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <p id="labels">Apply Link</p>
            <TextField
              fullWidth
              name="url"
              value={formData.url}
              onChange={handleInputChange}
              label="Apply Link"
              variant="outlined"
              type="url"
              error={!!errors.url}
              helperText={errors.url}
              required
            />
          </Grid>
        </Grid>
        <div className='login-btn' style={{ marginTop: 30 }}>
          <LoadingButton 
            type="submit" 
            variant="contained" 
            id='login-btn'
            loading={loading}
          >
            {isEditing ? "Update" : "Submit"}
          </LoadingButton>
          {isEditing && (
            <button
              type="button"
              onClick={resetForm}
              style={{ marginLeft: '10px', padding: '8px 16px' }}
              disabled={loading}
            >
              Cancel
            </button>
          )}
        </div>
      </form>
      
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Last Date</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Move Up</th>
            <th>Move Down</th>
          </tr>
        </thead>
        <tbody>
          {internships.map(internship => (
            <tr key={internship.id}>
              <td>{internship.title}</td> {/* Changed from job_title to title */}
              <td>{internship.description}</td>
              <td>{internship.date}</td> {/* Changed from last_date to date */}
              <td>
                <button
                  className='tablebutton'
                  onClick={() => handleEdit(internship)}
                  disabled={loading}
                >
                  Edit
                </button>
              </td>
              <td>
                <button
                  className='tablebutton'
                  onClick={() => handleDelete(internship.id)}
                  disabled={loading}
                >
                  Delete
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("up", internship.id)}
                >
                  <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("down", internship.id)}
                >
                  <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}