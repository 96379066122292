// import React, { useState } from "react";
// import { Table, Button, Form, Container, Row, Col } from "react-bootstrap";

// export const Ombudsman = () => {
//   const [ombudsmanData, setOmbudsmanData] = useState([
//     {
//       id: 1,
//       name: "Shri . A. Chandrakumaran Nair",
//       description:
//         "Electricity Ombudsman is a statutory authority appointed or designated by the State Commission. Any consumer aggrieved by non-redressal of the grievance under CGRF, may approach the Electricity Ombudsman. The Ombudsman has been entrusted with the task of settling the grievance of consumers as per the provisions of KSERC (Consumer Grievance Redressal and Electricity Ombudsman) Regulations 2005.",
//       address:
//         "The State Electricity Ombudsman, D.H Road, Offshore Road Junction, Near Gandhi Square, Ernakulam, Kerala - 682 016",
//       phone: "0484-2346488",
//       mobile: "+91 8714356488",
//       email: "ombudsman.electricity@gmail.com",
//       website: "www.keralaeo.org",
//       latestOrderLink: "#",
//       image: "https://www.erckerala.org/static/media/ombuds-img.5ef901cd1db2553de483.jpeg"
//     },
//   ]);

//   const [formData, setFormData] = useState({
//     name: "",
//     description: "",
//     address: "",
//     phone: "",
//     mobile: "",
//     email: "",
//     website: "",
//     latestOrderLink: "",
//      image: ""
//   });

//   const [editingId, setEditingId] = useState(null);
//   const handleChange = (e) => {
//     if (e.target.name === "image") {
//       setFormData({ ...formData, image: URL.createObjectURL(e.target.files[0]) });
//     } else {
//       setFormData({ ...formData, [e.target.name]: e.target.value });
//     }
//   };



//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (editingId) {
//       setOmbudsmanData(
//         ombudsmanData.map((item) => (item.id === editingId ? { ...item, ...formData } : item))
//       );
//       setEditingId(null);
//     } else {
//       setOmbudsmanData([...ombudsmanData, { id: Date.now(), ...formData }]);
//     }
//     setFormData({
//       name: "",
//       description: "",
//       address: "",
//       phone: "",
//       mobile: "",
//       email: "",
//       website: "",
//       latestOrderLink: "",
//          image: ""
//     });
//   };

//   const handleEdit = (id) => {
//     const recordToEdit = ombudsmanData.find((item) => item.id === id);
//     setFormData(recordToEdit);
//     setEditingId(id);
//   };

//   const handleDelete = (id) => {
//     setOmbudsmanData(ombudsmanData.filter((item) => item.id !== id));
//   };

//   return (
//    <div>
//       <Container>
//         <h2 className="organisation-head">Ombudsman</h2>
//         <Form onSubmit={handleSubmit}>
//           <Row>
//   <Col md={6}>
//   <Form.Group controlId="formImage">
//             <Form.Label>Image</Form.Label>
//             <Form.Control type="file" name="image" onChange={handleChange} />
//           </Form.Group>
//   </Col>

  
//             <Col md={6}>
//               <Form.Group controlId="formName">
//                 <Form.Label>Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                   required
//                 />
//               </Form.Group>
//             </Col>
//        <Col md={6}>
//               <Form.Group controlId="formDescription">
//               <Form.Label>Description</Form.Label>
//               <Form.Control
//                 as="textarea"
//                 rows={3}
//                 name="description"
//                 value={formData.description}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//        </Col>
//        <Col md={6}>
//             <Form.Group controlId="formAddress">
//               <Form.Label>Address</Form.Label>
//               <Form.Control
//                 as="textarea"
//                 rows={3}
//                 name="address"
//                 value={formData.address}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//             <Col md={6}>
//               <Form.Group controlId="formPhone">
//                 <Form.Label>Phone</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="phone"
//                   value={formData.phone}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Col>
        
//             <Col md={6}>
//               <Form.Group controlId="formMobile">
//                 <Form.Label>Mobile</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="mobile"
//                   value={formData.mobile}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Col>
//             <Col md={6}>
//               <Form.Group controlId="formEmail">
//                 <Form.Label>Email</Form.Label>
//                 <Form.Control
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Col>
        
//             <Col md={6}>
//               <Form.Group controlId="formWebsite">
//                 <Form.Label>Website</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="website"
//                   value={formData.website}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Col>
//             <Col md={6}>
//               <Form.Group controlId="formLatestOrderLink">
//                 <Form.Label>Latest Order Link</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="latestOrderLink"
//                   value={formData.latestOrderLink}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Col>
         
         
//           </Row>
//           <Button variant="primary" type="submit" className="mt-3">
//             {editingId ? "Update Ombudsman" : "Add Ombudsman"}
//           </Button>
//         </Form>
//         </Container>
        
//        <Container fluid>
//           <Table striped bordered hover variant="light" className="mt-4">
//             <thead>
//               <tr>
//               <th>Image</th>
//                 <th>Name</th>
//                 <th>Description</th>
//                 <th>Address</th>
//                 <th>Phone</th>
//                 <th>Mobile</th>
//                 <th>Email</th>
//                 <th>Website</th>
//                 <th>Latest Order</th>
//                 <th>Edit</th>
//                 <th>Delete</th>
//               </tr>
//             </thead>
//             <tbody>
//               {ombudsmanData.map((ombudsman) => (
//                 <tr key={ombudsman.id}>
//                    <td>
//                     {ombudsman.image && <img src={ombudsman.image} alt="Ombudsman" width="50" />}
//                   </td>
//                   <td>{ombudsman.name}</td>
//                   <td>{ombudsman.description}</td>
//                   <td>{ombudsman.address}</td>
//                   <td>{ombudsman.phone}</td>
//                   <td>{ombudsman.mobile}</td>
//                   <td>{ombudsman.email}</td>
//                   <td>{ombudsman.website}</td>
//                   <td>
//                     <a href={ombudsman.latestOrderLink} target="_blank" rel="noopener noreferrer">
//                       View Order
//                     </a>
//                   </td>
//                   <td>
//                     <button className="tablebutton"  onClick={() => handleEdit(ombudsman.id)}>
//                       Edit
//                     </button>
//                   </td>
//                   <td>
//                     <button className="tablebutton"  onClick={() => handleDelete(ombudsman.id)}>
//                       Delete
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//        </Container>
//    </div>
    
//   );
// };



import React, { useState, useEffect } from "react";
import { Table, Button, Form, Container, Row, Col } from "react-bootstrap";
import axios from "axios";

export const Ombudsman = () => {
  const [ombudsmanData, setOmbudsmanData] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    address: "",
    phone: "",
    mobile: "",
    email: "",
    website: "",
    order_link: "",
    image_upload: null
  });
  const [editingId, setEditingId] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  // Fetch ombudsman data from API
  useEffect(() => {
    const fetchOmbudsmanData = async () => {
      try {
        const response = await axios.get("https://dev.erckerala.org/api/ombudsman");
        setOmbudsmanData(response.data.data);
      } catch (error) {
        console.error("Error fetching ombudsman data:", error);
      }
    };
    fetchOmbudsmanData();
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "image_upload") {
      const file = e.target.files[0];
      setFormData({ ...formData, image_upload: file });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      if (formData[key] !== null && formData[key] !== undefined) {
        formDataToSend.append(key, formData[key]);
      }
    });
  
    try {
      let response;
      if (editingId) {
        // For update, make sure to include the _method parameter for Laravel
        formDataToSend.append('_method', 'PUT');
        response = await axios.post(
          `https://dev.erckerala.org/api/ombudsman/${editingId}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              // Add authorization header if needed
              // "Authorization": `Bearer ${yourToken}`
            },
          }
        );
      } else {
        response = await axios.post(
          "https://dev.erckerala.org/api/ombudsman",
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              // Add authorization header if needed
              // "Authorization": `Bearer ${yourToken}`
            },
          }
        );
      }
      
      // Refresh data
      const updatedResponse = await axios.get("https://dev.erckerala.org/api/ombudsman");
      setOmbudsmanData(updatedResponse.data.data);
      
      // Reset form
      resetForm();
    } catch (error) {
      console.error("Error submitting ombudsman data:", error);
      // Log the detailed error response
      if (error.response) {
        console.error("Error details:", error.response.data);
      }
    }
  };
  
  const resetForm = () => {
    setFormData({
      name: "",
      description: "",
      address: "",
      phone: "",
      mobile: "",
      email: "",
      website: "",
      order_link: "",
      image_upload: null
    });
    setImagePreview("");
    setEditingId(null);
  };

  const handleEdit = (id) => {
    const recordToEdit = ombudsmanData.find((item) => item.id === id);
    setFormData({
      name: recordToEdit.name,
      description: recordToEdit.description,
      address: recordToEdit.address,
      phone: recordToEdit.phone,
      mobile: recordToEdit.mobile,
      email: recordToEdit.email,
      website: recordToEdit.website,
      order_link: recordToEdit.order_link,
      image_upload: null
    });
    setImagePreview(recordToEdit.image || "");
    setEditingId(id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://dev.erckerala.org/api/ombudsman/${id}`);
      setOmbudsmanData(ombudsmanData.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting ombudsman record:", error);
    }
  };

  return (
    <div>
      <Container>
        <h2 className="organisation-head">Ombudsman</h2>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formImage">
                <Form.Label>Image</Form.Label>
                <Form.Control 
                  type="file" 
                  name="image_upload" 
                  onChange={handleChange} 
                />
                {imagePreview && (
                  <img 
                    src={imagePreview} 
                    alt="Preview" 
                    style={{ width: "100px", marginTop: "10px" }} 
                  />
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            
            <Col md={6}>
              <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            
            <Col md={6}>
              <Form.Group controlId="formAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            
            <Col md={6}>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            
            <Col md={6}>
              <Form.Group controlId="formMobile">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            
            <Col md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            
            <Col md={6}>
              <Form.Group controlId="formWebsite">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            
            <Col md={6}>
              <Form.Group controlId="formOrderLink">
                <Form.Label>Latest Order Link</Form.Label>
                <Form.Control
                  type="text"
                  name="order_link"
                  value={formData.order_link}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          
          <Button variant="primary" type="submit" className="mt-3">
            {editingId ? "Update Ombudsman" : "Add Ombudsman"}
          </Button>
        </Form>
      </Container>
      
      <Container fluid>
        <Table striped bordered hover variant="light" className="mt-4">
          <thead>
            <tr>
              
              <th>Name</th>
              <th>Description</th>
              <th>Address</th>
              <th>Phone</th>
              <th>Mobile</th>
              <th>Email</th>
              <th>Website</th>
              <th>Image</th>
              <th>Latest Order</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {ombudsmanData.map((ombudsman) => (
              <tr key={ombudsman.id}>
                
                <td>{ombudsman.name}</td>
                <td>{ombudsman.description}</td>
                <td>{ombudsman.address}</td>
                <td>{ombudsman.phone}</td>
                <td>{ombudsman.mobile}</td>
                <td>{ombudsman.email}</td>
                <td>{ombudsman.website}</td>
                <td>
                  {ombudsman.image && (
                    <img 
                      src={ombudsman.image} 
                      alt="Ombudsman" 
                      width="50" 
                    />
                  )}
                </td>
                <td>
                  <a href={ombudsman.order_link} target="_blank" rel="noopener noreferrer">
                    View Order
                  </a>
                </td>
                <td>
                  <button className="tablebutton" onClick={() => handleEdit(ombudsman.id)}>
                    Edit
                  </button>
                </td>
                <td>
                  <button className="tablebutton" onClick={() => handleDelete(ombudsman.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};
