import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';
import APIService from '../../../../APIService';


export const ResearchThumbnail = () => {
  const [thumbnails, setThumbnails] = useState([]);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [fileUpload, setFileUpload] = useState(null);
  const [researchId, setResearchId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);

  // Fetch research data from API
  useEffect(() => {
    fetchResearchData();
  }, []);

  const fetchResearchData = async () => {
    try {
      const response = await fetch('https://dev.erckerala.org/api/research');
      if (!response.ok) {
        throw new Error('Failed to fetch research data');
      }
      const data = await response.json();
      setThumbnails(data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching research data:', error);
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFileUpload(e.target.files[0]);
    }
  };

  const handleEdit = (item) => {
    setResearchId(item.id);
    setIsEditing(true);
    setTitle(item.title);
    setDate(item.date.split('T')[0]);
  };

  const handleDelete = async (itemId) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this research item?",
      icon: "warning",
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await fetch(`https://dev.erckerala.org/api/research/${itemId}`, {
            method: 'DELETE'
          });
          
          if (!response.ok) {
            throw new Error('Failed to delete research item');
          }
          
          setThumbnails(thumbnails.filter(item => item.id !== itemId));
          swal("Deleted!", "The research item has been deleted.", "success");
        } catch (error) {
          console.error('Error deleting research item:', error);
          swal("Error!", "Failed to delete research item.", "error");
        }
      }
    });
  };

  const resetForm = () => {
    setTitle("");
    setDate("");
    setFileUpload(null);
    setIsEditing(false);
    setResearchId("");
  };

  // const changePosition = (direction, itemId) => {
  //   APIService.post([], `change-position/${direction}/research/${itemId}`)
  //     .then((response) => {
  //       if (response.success) {
  //         fetchResearchData();
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Position change error:', error);
  //       swal("Error", "Failed to change position", "error");
  //     });
  // };



  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!title || !date) {
      swal("Error!", "Title and Date are required fields", "error");
      return;
    }
  
    const formData = new FormData();
    formData.append('title', title);
    formData.append('date', date);
    
    if (fileUpload) {
      formData.append('file_upload', fileUpload);
    }
  
    // Explicitly tell Laravel not to use timestamps
    formData.append('_skip_timestamps', 'true');
  
    try {
      const headers = {
        'Accept': 'application/json',
        // Add authorization if needed
        // 'Authorization': `Bearer ${localStorage.getItem('token')}`
      };
  
      const url = isEditing 
        ? `https://dev.erckerala.org/api/research/${researchId}`
        : 'https://dev.erckerala.org/api/research';
  
      const method = isEditing ? 'PUT' : 'POST';
  
      const response = await fetch(url, {
        method,
        body: formData,
        headers
      });
  
      const text = await response.text();
      let responseData;
      
      try {
        responseData = JSON.parse(text);
      } catch {
        console.error('Server returned:', text);
        throw new Error('Server returned an invalid response');
      }
  
      if (!response.ok) {
        throw new Error(responseData.message || `Request failed with status ${response.status}`);
      }
  
      swal("Success!", isEditing ? "Research updated successfully!" : "Research added successfully!", "success");
      fetchResearchData();
      resetForm();
  
    } catch (error) {
      console.error('Full error:', error);
      swal("Error!", error.message || "Submission failed. Please check console.", "error");
    }
  };

 

  if (loading) {
    return <div>Loading research data...</div>;
  }

  return (
    <div>
      <h2 className="organisation-head">Research</h2>

      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} className='mt-2'>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField 
              label="Title" 
              variant="outlined" 
              fullWidth 
              value={title} 
              onChange={e => setTitle(e.target.value)} 
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField 
              type="date" 
              variant="outlined" 
              fullWidth 
              value={date} 
              onChange={e => setDate(e.target.value)} 
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField 
              type="file" 
              onChange={handleFileChange} 
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                accept: ".pdf,.doc,.docx" // Limit to document files
              }}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type='submit'>
          {isEditing ? "Update" : "Upload"}
        </Button>
        {isEditing && (
          <Button 
            style={{ marginTop: 20, marginLeft: 10 }} 
            variant="secondary" 
            onClick={resetForm}
          >
            Cancel
          </Button>
        )}
      </form>

      <h3 className='mt-5'>Research List</h3>
      <Table striped bordered hover variant="light" responsive="sm mt-5">
        <thead>
          <tr>
            <th>Sr.No</th>
            <th>Research Title</th>
            <th>Date</th>
            <th>Edit</th>
            <th>Delete</th>
            {/* <th>Move Up</th>
            <th>Move Down</th> */}
          </tr>
        </thead>
        <tbody>
          {thumbnails.map((thumbnail, index) => (
            <tr key={thumbnail.id}>
              <td>{index + 1}</td>
              <td>{thumbnail.title}</td>
              <td>{new Date(thumbnail.date).toLocaleDateString()}</td>
              <td>
                <button 
                  className='tablebutton' 
                  onClick={() => handleEdit(thumbnail)}
                >
                  Edit
                </button>
              </td>
              <td>
                <button 
                  className='tablebutton' 
                  onClick={() => handleDelete(thumbnail.id)}
                >
                  Delete
                </button>
              </td>
              {/* <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("up", thumbnail.id)}
                >
                  <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("down", thumbnail.id)}
                >
                  <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
// import React, { useState, useEffect } from 'react';
// import { Grid, TextField } from '@mui/material';
// import Table from 'react-bootstrap/Table';
// import Button from 'react-bootstrap/Button';
// import swal from 'sweetalert';
// import axios from 'axios';

// export const ResearchThumbnail = () => {
//   const [thumbnails, setThumbnails] = useState([]);
//   const [heading, setHeading] = useState("");
//   const [year, setYear] = useState("");
//   const [fileUpload, setFileUpload] = useState(null);
//   const [researchId, setResearchId] = useState("");
//   const [isEditing, setIsEditing] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);

//   // API endpoint (replace with your actual API base URL)
//   const API_URL = "https://dev.erckerala.org/api/research";

//   // Fetch all research thumbnails
//   const fetchResearchThumbnails = async () => {
//     try {
//       setIsLoading(true);
//       const response = await axios.get(API_URL);
//       setThumbnails(response.data);
//       setIsLoading(false);
//     } catch (error) {
//       console.error("Error fetching research thumbnails:", error);
//       swal("Error", "Failed to fetch research data", "error");
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchResearchThumbnails();
//   }, []);

//   const handleFileChange = (e) => {
//     if (e.target.files) {
//       setFileUpload(e.target.files[0]);
//     }
//   };

//   const handleEdit = (item) => {
//     setResearchId(item.id);
//     setIsEditing(true);
//     setHeading(item.heading);
//     setYear(item.year);
//   };

//   const handleDelete = async (itemId) => {
//     swal({
//       title: "Are you sure?",
//       text: "You want to delete this item?",
//       icon: "warning",
//       dangerMode: true,
//     }).then(async (willDelete) => {
//       if (willDelete) {
//         try {
//           await axios.delete(`${API_URL}/${itemId}`);
//           setThumbnails(thumbnails.filter(item => item.id !== itemId));
//           swal("Success", "Research deleted successfully", "success");
//         } catch (error) {
//           console.error("Error deleting research:", error);
//           swal("Error", "Failed to delete research", "error");
//         }
//       }
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     if (!heading || !year) {
//       swal("Error", "Please fill all required fields", "error");
//       return;
//     }

//     const formData = new FormData();
//     formData.append('heading', heading);
//     formData.append('year', year);
//     if (fileUpload) {
//       formData.append('image', fileUpload);
//     }

//     try {
//       if (isEditing) {
//         // Update existing research
//         const response = await axios.put(`${API_URL}/${researchId}`, formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data'
//           }
//         });
//         setThumbnails(thumbnails.map(item => (item.id === researchId ? response.data : item)));
//         swal("Success", "Research updated successfully", "success");
//       } else {
//         // Create new research
//         const response = await axios.post(API_URL, formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data'
//           }
//         });
//         setThumbnails([...thumbnails, response.data]);
//         swal("Success", "Research added successfully", "success");
//       }
      
//       // Reset form
//       setHeading("");
//       setYear("");
//       setFileUpload(null);
//       setIsEditing(false);
//     } catch (error) {
//       console.error("Error saving research:", error);
//       swal("Error", "Failed to save research", "error");
//     }
//   };

//   // Function to move item up in the list
//   const moveItemUp = async (index) => {
//     if (index === 0) return;
    
//     try {
//       const item = thumbnails[index];
//       const response = await axios.put(`${API_URL}/${item.id}/move-up`);
//       const updatedThumbnails = [...thumbnails];
//       [updatedThumbnails[index], updatedThumbnails[index - 1]] = 
//         [updatedThumbnails[index - 1], updatedThumbnails[index]];
//       setThumbnails(updatedThumbnails);
//     } catch (error) {
//       console.error("Error moving item up:", error);
//       swal("Error", "Failed to reorder items", "error");
//     }
//   };

//   // Function to move item down in the list
//   const moveItemDown = async (index) => {
//     if (index === thumbnails.length - 1) return;
    
//     try {
//       const item = thumbnails[index];
//       const response = await axios.put(`${API_URL}/${item.id}/move-down`);
//       const updatedThumbnails = [...thumbnails];
//       [updatedThumbnails[index], updatedThumbnails[index + 1]] = 
//         [updatedThumbnails[index + 1], updatedThumbnails[index]];
//       setThumbnails(updatedThumbnails);
//     } catch (error) {
//       console.error("Error moving item down:", error);
//       swal("Error", "Failed to reorder items", "error");
//     }
//   };

//   return (
//     <div>
//       <h2 className="organisation-head">Research</h2>
     
//       <form noValidate onSubmit={handleSubmit}>
//         <Grid container spacing={3} className='mt-2'>
//           <Grid item xs={12} sm={6} lg={4}>
//             <TextField 
//               label="Heading" 
//               variant="outlined" 
//               fullWidth 
//               value={heading} 
//               onChange={e => setHeading(e.target.value)} 
//               required
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <TextField  
//               type="date"  
//               variant="outlined" 
//               fullWidth 
//               value={year} 
//               onChange={e => setYear(e.target.value)} 
//               required
//               InputLabelProps={{
//                 shrink: true,
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} lg={4}>
//             <TextField 
//               type="file" 
//               onChange={handleFileChange}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Button style={{ marginTop: 20 }} variant="primary" type='submit' disabled={isLoading}>
//           {isLoading ? "Processing..." : isEditing ? "Update" : "Upload"}
//         </Button>
//       </form>

//       <h3 className='mt-5'>Research List</h3>
//       {isLoading ? (
//         <p>Loading research data...</p>
//       ) : (
//         <Table striped bordered hover variant="light" responsive="sm mt-5">
//           <thead>
//             <tr>
//               <th>Sr.No</th>
//               <th>Research Heading</th>
//               <th>Date</th>
//               <th>Edit</th>
//               <th>Delete</th>
//               <th>Move Up</th>
//               <th>Move Down</th>
//             </tr>
//           </thead>
//           <tbody>
//             {thumbnails.map((thumbnail, index) => (
//               <tr key={thumbnail.id}>
//                 <td>{index + 1}</td>
//                 <td>{thumbnail.heading}</td>
//                 <td>{new Date(thumbnail.year).toLocaleDateString()}</td>
//                 <td>
//                   <button 
//                     className='tablebutton' 
//                     onClick={() => handleEdit(thumbnail)}
//                     disabled={isLoading}
//                   >
//                     Edit
//                   </button>
//                 </td>
//                 <td>
//                   <button 
//                     className='tablebutton' 
//                     onClick={() => handleDelete(thumbnail.id)}
//                     disabled={isLoading}
//                   >
//                     Delete
//                   </button>
//                 </td>
//                 <td>
//                   <button
//                     className='move-btn'
//                     onClick={() => moveItemUp(index)}
//                     disabled={isLoading || index === 0}
//                   >
//                     <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
//                   </button>
//                 </td>
//                 <td>
//                   <button
//                     className='move-btn'
//                     onClick={() => moveItemDown(index)}
//                     disabled={isLoading || index === thumbnails.length - 1}
//                   >
//                     <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//       )}
//     </div>
//   );
// };