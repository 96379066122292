// import React, { useState } from "react";
// import { Grid, TextField } from "@mui/material";
// import Button from "react-bootstrap/Button";
// import swal from "sweetalert";
// import { Table } from "react-bootstrap";

// export const HomeCarousel = () => {
//   const [fileUpload, setFileUpload] = useState(null);

//   const handleFileChange = (e) => {
//     if (e.target.files) {
//       setFileUpload(e.target.files[0]);
//     }
//   };

//   return (
//     <div>
//       <h2 className="organisation-head">Home Carousel</h2>
//       <form noValidate>
//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Upload Image</p>
//             <TextField name="upload-photo" type="file" onChange={handleFileChange} fullWidth />
//           </Grid>
//         </Grid>
//         <Button style={{ marginTop: 60 }} variant="primary" type="submit">
//           Submit
//         </Button>
//       </form>

//       <Table striped bordered hover variant="light" className="mt-5">
//         <thead>
//           <tr>
//             <th>Sl No</th>
//             <th>Image</th>
//             <th>Edit</th>
//             <th>Delete</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td>1</td>
//             <td>
//               <img src="https://dev.erckerala.org/api/storage/banner/KLE1qvAkqfESs0pAJ9aCvp5EaLHHhu6nyDiWLxlo.jpg" alt="Carousel 1" width="100" />
//             </td>
//             <td>
//               <button className="tablebutton">Edit</button>
//             </td>
//             <td>
//               <button className="tablebutton">Delete</button>
//             </td>
//           </tr>
//           <tr>
//             <td>2</td>
//             <td>
//               <img src="https://dev.erckerala.org/api/storage/banner/DZ0fGkOFcIfBXJKGLXZ3xq9JLEPWG2Onre7qqLKr.jpg" alt="Carousel 2" width="100" />
//             </td>
//             <td>
//               <button className="tablebutton">Edit</button>
//             </td>
//             <td>
//               <button className="tablebutton">Delete</button>
//             </td>
//           </tr>
//           <tr>
//             <td>3</td>
//             <td>
//               <img src="https://dev.erckerala.org/api/storage/banner/jHzvyycK0jJD75fQz97qkV0N1YZ9zlcYOKgmCLQ8.jpg" alt="Carousel 3" width="100" />
//             </td>
//             <td>
//               <button className="tablebutton">Edit</button>
//             </td>
//             <td>
//               <button className="tablebutton">Delete</button>
//             </td>
//           </tr>
//         </tbody>
//       </Table>
//     </div>
//   );
// };
// import React, { useState, useEffect } from "react";
// import { Grid, TextField } from "@mui/material";
// import Button from "react-bootstrap/Button";
// import { Table } from "react-bootstrap";
// import axios from "axios";

// export const HomeCarousel = () => {
//   const [fileUpload, setFileUpload] = useState(null);
//   const [thumbnailUpload, setThumbnailUpload] = useState(null);
//   const [carouselId, setCarouselId] = useState("");
//   const [isEditing, setIsEditing] = useState(false);
//   const [previewImage, setPreviewImage] = useState(null);
//   const [previewThumbnail, setPreviewThumbnail] = useState(null);
//   const [heading, setHeading] = useState("");
//   const [banners, setBanners] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     fetchBanners();
//   }, []);

//   const fetchBanners = async () => {
//     try {
//       const response = await axios.get("https://dev.erckerala.org/api/banner");
//       setBanners(response.data.data);
//       setLoading(false);
//       setError(null);
//     } catch (error) {
//       console.error("Error fetching banners:", error);
//       setError("Failed to fetch banners. Please try again.");
//       setLoading(false);
//     }
//   };

//   const handleFileChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       const file = e.target.files[0];
//       setFileUpload(file);
//       setPreviewImage(URL.createObjectURL(file));
//     }
//   };

//   const handleThumbnailChange = (e) => {
//     if (e.target.files && e.target.files[0]) {
//       const file = e.target.files[0];
//       setThumbnailUpload(file);
//       setPreviewThumbnail(URL.createObjectURL(file));
//     }
//   };

//   const handleHeadingChange = (e) => {
//     setHeading(e.target.value);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError(null);

//     // Validate required fields
//     if (!heading) {
//       setError("Heading is required");
//       return;
//     }

//     if (!isEditing && !fileUpload) {
//       setError("Main image is required when creating a new banner");
//       return;
//     }

//     if (!isEditing && !thumbnailUpload) {
//       setError("Thumbnail image is required when creating a new banner");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("heading", heading);
    
//     if (fileUpload) {
//       formData.append("image", fileUpload);
//     }
    
//     if (thumbnailUpload) {
//       formData.append("thumbnail_image", thumbnailUpload);
//     } else if (fileUpload) {
//       // Fallback: use main image as thumbnail if thumbnail not provided
//       formData.append("thumbnail_image", fileUpload);
//     }

//     try {
//       const url = isEditing 
//         ? `https://dev.erckerala.org/api/banner/${carouselId}`
//         : "https://dev.erckerala.org/api/banner";
      
//       const method = isEditing ? "put" : "post";
      
//       const response = await axios[method](url, formData, {
//         headers: { "Content-Type": "multipart/form-data" },
//       });

//       console.log("API Response:", response.data);
//       resetForm();
//       fetchBanners();
//     } catch (error) {
//       console.error("Error submitting banner:", error);
//       let errorMessage = "Failed to submit banner.";
      
//       if (error.response) {
//         // Handle validation errors from backend
//         if (error.response.data.errors) {
//           errorMessage = Object.entries(error.response.data.errors)
//             .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
//             .join('; ');
//         } else if (error.response.data.message) {
//           errorMessage = error.response.data.message;
//         }
//       } else if (error.message) {
//         errorMessage = error.message;
//       }
      
//       setError(errorMessage);
//     }
//   };

//   const handleEdit = (banner) => {
//     setCarouselId(banner.id);
//     setHeading(banner.heading);
//     setPreviewImage(banner.image);
//     setPreviewThumbnail(banner.thumbnail || banner.image); // Use thumbnail if available, fallback to main image
//     setIsEditing(true);
//     setFileUpload(null);
//     setThumbnailUpload(null);
//   };

//   const handleDelete = async (id) => {
//     try {
//       await axios.delete(`https://dev.erckerala.org/api/banner/${id}`);
//       fetchBanners();
//     } catch (error) {
//       console.error("Error deleting banner:", error);
//       setError(
//         error.response?.data?.message || 
//         error.message || 
//         "Failed to delete banner. Please try again."
//       );
//     }
//   };

//   const resetForm = () => {
//     setFileUpload(null);
//     setThumbnailUpload(null);
//     setHeading("");
//     setPreviewImage(null);
//     setPreviewThumbnail(null);
//     setCarouselId("");
//     setIsEditing(false);
//     setError(null);
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <h2 className="organisation-head">Home Carousel</h2>
      
//       {error && (
//         <div className="alert alert-danger" role="alert">
//           {error}
//         </div>
//       )}

//       <form noValidate onSubmit={handleSubmit}>
//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Heading *</p>
//             <TextField
//               name="heading"
//               value={heading}
//               onChange={handleHeadingChange}
//               fullWidth
//               required
//               error={error?.toLowerCase().includes("heading")}
//               helperText={error?.toLowerCase().includes("heading") ? error : ""}
//             />
//           </Grid>
          
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Main Image *</p>
//             <TextField 
//               name="main-image" 
//               type="file" 
//               onChange={handleFileChange} 
//               fullWidth 
//               required={!isEditing}
//               inputProps={{ accept: "image/*" }}
//               error={error?.toLowerCase().includes("main image")}
//             />
//             {previewImage && (
//               <div style={{ marginTop: 10 }}>
//                 <img
//                   src={previewImage}
//                   alt="Main Preview"
//                   width="100"
//                   height="80"
//                   style={{ borderRadius: 5, border: "1px solid #ccc" }}
//                 />
//                 <p style={{ fontSize: 12, color: '#666' }}>Main Banner Image</p>
//               </div>
//             )}
//           </Grid>
          
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Thumbnail Image *</p>
//             <TextField 
//               name="thumbnail-image" 
//               type="file" 
//               onChange={handleThumbnailChange} 
//               fullWidth 
//               required={!isEditing}
//               inputProps={{ accept: "image/*" }}
//               error={error?.toLowerCase().includes("thumbnail")}
//             />
//             {previewThumbnail && (
//               <div style={{ marginTop: 10 }}>
//                 <img
//                   src={previewThumbnail}
//                   alt="Thumbnail Preview"
//                   width="100"
//                   height="80"
//                   style={{ borderRadius: 5, border: "1px solid #ccc" }}
//                 />
//                 <p style={{ fontSize: 12, color: '#666' }}>Thumbnail Image</p>
//               </div>
//             )}
//           </Grid>
//         </Grid>

//         <Button style={{ marginTop: 20 }} variant="primary" type="submit">
//           {isEditing ? "Update" : "Submit"}
//         </Button>
//         {isEditing && (
//           <Button 
//             style={{ marginTop: 20, marginLeft: 10 }} 
//             variant="secondary" 
//             onClick={resetForm}
//           >
//             Cancel
//           </Button>
//         )}
//       </form>

//       <Table striped bordered hover variant="light" className="mt-5">
//         <thead>
//           <tr>
//             <th>Sl No</th>
//             <th>Heading</th>
//             <th>Image</th>
//             <th>Thumbnail</th>
//             <th>Edit</th>
//             <th>Delete</th>
//           </tr>
//         </thead>
//         <tbody>
//           {banners.map((banner, index) => (
//             <tr key={banner.id}>
//               <td>{index + 1}</td>
//               <td>{banner.heading}</td>
//               <td>
//                 <img 
//                   src={banner.image} 
//                   alt={`Carousel ${banner.heading}`} 
//                   width="100" 
//                   onError={(e) => {
//                     e.target.onerror = null; 
//                     e.target.src = "https://via.placeholder.com/100";
//                   }}
//                 />
//               </td>
//               <td>
//                 <img 
//                   src={banner.thumbnail || banner.image} 
//                   alt={`Thumbnail ${banner.heading}`} 
//                   width="50" 
//                   onError={(e) => {
//                     e.target.onerror = null; 
//                     e.target.src = "https://via.placeholder.com/50";
//                   }}
//                 />
//               </td>
//               <td>
//                 <button 
//                   className="btn btn-primary" 
//                   onClick={() => handleEdit(banner)}
//                 >
//                   Edit
//                 </button>
//               </td>
//               <td>
//                 <button 
//                   className="btn btn-danger" 
//                   onClick={() => {
//                     if (window.confirm("Are you sure you want to delete this banner?")) {
//                       handleDelete(banner.id);
//                     }
//                   }}
//                 >
//                   Delete
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import Button from "react-bootstrap/Button";
import { Table } from "react-bootstrap";
import axios from "axios";

export const HomeCarousel = () => {
  const [fileUpload, setFileUpload] = useState(null);
  const [thumbnailUpload, setThumbnailUpload] = useState(null);
  const [carouselId, setCarouselId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewThumbnail, setPreviewThumbnail] = useState(null);
  const [heading, setHeading] = useState("");
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    try {
      const response = await axios.get("https://dev.erckerala.org/api/banner");
      setBanners(response.data.data);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.error("Error fetching banners:", error);
      setError("Failed to fetch banners. Please try again.");
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFileUpload(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleThumbnailChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setThumbnailUpload(file);
      setPreviewThumbnail(URL.createObjectURL(file));
    }
  };

  const handleHeadingChange = (e) => {
    setHeading(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);
  
    // Robust heading validation
    const trimmedHeading = (heading || "").toString().trim();
    if (!trimmedHeading) {
      setError("Heading is required");
      setIsSubmitting(false);
      return;
    }
  
    const formData = new FormData();
    
    // Always append heading - this was likely missing in your update
    formData.append("heading", trimmedHeading);
    
    // Only append files if they exist
    if (fileUpload) formData.append("image", fileUpload);
    if (thumbnailUpload) formData.append("thumbnail_image", thumbnailUpload);
  
    // For Laravel API, you might need this for PUT requests
    if (isEditing) {
      formData.append("_method", "PUT");
    }
  
    try {
      const url = isEditing 
        ? `https://dev.erckerala.org/api/banner/${carouselId}`
        : "https://dev.erckerala.org/api/banner";
      
      const method = isEditing ? "post" : "post"; // Use post for both with _method
      
      const response = await axios[method](url, formData, {
        headers: { 
          "Content-Type": "multipart/form-data",
          "Accept": "application/json"
        },
      });
  
      console.log("API Response:", response.data);
      resetForm();
      fetchBanners();
    } catch (error) {
      console.error("Error submitting banner:", error);
      let errorMessage = "Failed to submit banner.";
      
      if (error.response?.data?.errors?.heading) {
        errorMessage = error.response.data.errors.heading.join(', ');
      } else if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      
      setError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = (banner) => {
    console.log("Editing banner:", banner); // Debug what's coming from API
    setCarouselId(banner.id);
    setHeading(banner.heading || "");
    setPreviewImage(banner.image);
    setPreviewThumbnail(banner.thumbnail || banner.image);
    setIsEditing(true);
    setFileUpload(null);
    setThumbnailUpload(null);
    setError(null);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this banner?")) {
      try {
        await axios.delete(`https://dev.erckerala.org/api/banner/${id}`);
        fetchBanners();
      } catch (error) {
        console.error("Error deleting banner:", error);
        setError(
          error.response?.data?.message || 
          error.message || 
          "Failed to delete banner. Please try again."
        );
      }
    }
  };

  const resetForm = () => {
    setFileUpload(null);
    setThumbnailUpload(null);
    setHeading("");
    setPreviewImage(null);
    setPreviewThumbnail(null);
    setCarouselId("");
    setIsEditing(false);
    setError(null);
  };

  if (loading) {
    return <div className="text-center mt-5">Loading banners...</div>;
  }

  return (
    <div className="container-fluid">
      <h2 className="organisation-head">Home Carousel</h2>
      
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Heading *</p>
            <TextField
              name="heading"
              value={heading}
              onChange={handleHeadingChange}
              fullWidth
              required
              error={error?.toLowerCase().includes("heading")}
              helperText={error?.toLowerCase().includes("heading") ? error : ""}
            />
          </Grid>
          
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Main Image {!isEditing && '*'}</p>
            <TextField 
              name="main-image" 
              type="file" 
              onChange={handleFileChange} 
              fullWidth 
              required={!isEditing}
              inputProps={{ accept: "image/*" }}
              error={error?.toLowerCase().includes("main image")}
            />
            {previewImage && (
              <div style={{ marginTop: 10 }}>
                <img
                  src={previewImage}
                  alt="Main Preview"
                  width="100"
                  height="80"
                  style={{ borderRadius: 5, border: "1px solid #ccc" }}
                />
                <p style={{ fontSize: 12, color: '#666' }}>Main Banner Image</p>
              </div>
            )}
          </Grid>
          
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Thumbnail Image {!isEditing && '*'}</p>
            <TextField 
              name="thumbnail-image" 
              type="file" 
              onChange={handleThumbnailChange} 
              fullWidth 
              required={!isEditing}
              inputProps={{ accept: "image/*" }}
              error={error?.toLowerCase().includes("thumbnail")}
            />
            {previewThumbnail && (
              <div style={{ marginTop: 10 }}>
                <img
                  src={previewThumbnail}
                  alt="Thumbnail Preview"
                  width="100"
                  height="80"
                  style={{ borderRadius: 5, border: "1px solid #ccc" }}
                />
                <p style={{ fontSize: 12, color: '#666' }}>Thumbnail Image</p>
              </div>
            )}
          </Grid>
        </Grid>

        <div className="mt-4">
          <Button 
            variant="primary" 
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Processing...' : isEditing ? 'Update' : 'Submit'}
          </Button>
          {isEditing && (
            <Button 
              variant="secondary" 
              onClick={resetForm}
              className="ms-2"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          )}
        </div>
      </form>

      <Table striped bordered hover variant="light" className="mt-5">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Heading</th>
            <th>Image</th>
            <th>Thumbnail</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {banners.map((banner, index) => (
            <tr key={banner.id}>
              <td>{index + 1}</td>
              <td>{banner.heading}</td>
              <td>
                <img 
                  src={banner.image} 
                  alt={`Banner ${banner.heading}`} 
                  width="100" 
                  onError={(e) => {
                    e.target.onerror = null; 
                    e.target.src = "https://via.placeholder.com/100";
                  }}
                />
              </td>
              <td>
                <img 
                  src={banner.thumbnail || banner.image} 
                  alt={`Thumbnail ${banner.heading}`} 
                  width="50" 
                  onError={(e) => {
                    e.target.onerror = null; 
                    e.target.src = "https://via.placeholder.com/50";
                  }}
                />
              </td>
              <td>
                <div className="d-flex gap-2">
                  <button 
                      className='tablebutton' 
                    onClick={() => handleEdit(banner)}
                    disabled={isSubmitting}
                  >
                    Edit
                  </button>
                  <button 
                      className='tablebutton' 
                    onClick={() => handleDelete(banner.id)}
                    disabled={isSubmitting}
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};